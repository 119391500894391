<template>
  <v-app>
    <v-banner
      single-line
      height="500"
      class="banner"
    >
      <v-carousel class="carousel-new carousel-banner" height="500"
                  show-arrows-on-hover :cycle="true"
                  hide-delimiter-background :reverse="false"
                  :continuous="true">
        <v-carousel-item v-for="v in bannerList" :key="v.length">
          <v-img
            :src="v.bannerImg"
            height="100%"
            class="d-flex align-center"
            >
            <v-container color="transparent" class="px-6 max-width-1200 height-per-100 d-flex align-center">
              <div class="flex-grow-1">
                <v-card color="transparent" class="ml-0" flat>
                  <v-card-title class="pa-0 font-size-banner-title family-Bold" :style="'color: ' + v.titleColor + '!important;'">
                    <div>
                      <div v-for="(title,tipIndex) in v.title" :key="tipIndex" class="pt-2" style="font-weight: 500;">{{title}}</div>
                    </div>
                  </v-card-title>
                  <v-card-title class="pa-0 font-size-banner-tip mt-8" :style="'color: ' + v.titleColor + '!important;'">
                    <div>
                      <div v-for="(tip,tipIndex) in v.tip" :key="tipIndex" class="mb-3">{{tip}}</div>
                    </div>
                  </v-card-title>
                  <v-card-actions class="pa-0 mt-10" v-if="v.btn">
                    <v-btn
                        rounded
                        width="160"
                        height="50"
                        class="font-size-20"
                        @click.stop="handleBannerBtn(v)"
                    >
                      <span style="color: #2684EA">{{ v.btn }}</span>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </div>
              <div class="flex-grow-1">
                <v-img :src="v.drawIng"></v-img>
              </div>
            </v-container>
          </v-img>
        </v-carousel-item>
        
      </v-carousel>
    </v-banner>
    <!-- 为企业赋能 共同创造元宇宙世界 -->
    <v-card class="warps warp1" flat color="#FBFBFB">
      <div class="public-title">
        <p>CREATE A METAVERSE WORLD</p>
        <div>
          <span>为企业赋能 共同创造元宇宙世界</span>
        </div>
      </div>
      <div class="model_list1">
        <v-row no-gutters justify='space-between' class="px_102">
          <v-col class="px-4 mb-10"  v-for="(v, k) in modelList1" :key="k" cols="4" sm="4" >
            <div class="model_item">
              <img class="item_img" :src='v.icon' />
              <div class="item_info">
                <v-card-title class="justify-center reset-title">{{ v.title }}</v-card-title>
                <v-card-text class="reset-text">{{ v.text }}</v-card-text>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <!-- FT 3D引擎 -->
    <v-card class="warps warp2" flat>
      <div class="public-title">
        <p>FT 3D ENGINE</p>
        <div>
          <span>FT 3D引擎</span>
          <span>自主研发的三维引擎，可视化便捷操作，让你快速创作虚拟内容</span>
        </div>
      </div>
      <div class="model_list2">
        <v-row no-gutters justify='center'>
          <v-col class=""  v-for="(v, k) in modelList2" :key="k" cols="6" sm="4" >
            <div class="model_item">
              <img class="item_img" :src='v.img' />
              <div class="item_info" v-html="v.text"></div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <!-- aPaaS引擎 -->
    <v-card class="warps warp3" flat color="#FBFBFB">
      <div class="public-title">
        <p>aPaaS ENGINE</p>
        <div>
          <span>aPaaS引擎</span>
          <span>强大的工具让元宇宙内容的创作得心应手</span>
        </div>
      </div>
      <div class="model_list3">
        <v-row no-gutters justify='center'>
          <v-col class="px-4 mb-10"  v-for="(v, k) in modelList3" :key="k" cols="3">
            <div class="model_item">
              <img class="item_img" :src='v.icon' />
              <div class="item_info">
                <v-card-title class="justify-center reset-title">{{ v.title }}</v-card-title>
                <v-card-text class="reset-text">{{ v.text }}</v-card-text>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card class="warps warp4" flat>
      <div class="public-title">
        <p>DIGITAL ECOLOGY</p>
        <div>
          <span>数字生态</span>
        </div>
      </div>
      <div class="model_list4">
        <div class="center_item">
          <v-img class="item_img" src="https://h5.ophyer.cn/official_website/other/num_home_img_10.png"></v-img>
          <div class="item_info">
            <div>完整的</div>
            <div>生态系统</div>
          </div>
        </div>
        <div class="model_item" v-for="(v, k) in modelList4" :key="k" :class="v.className"
             style="backgroundImage: url('https://h5.ophyer.cn/official_website/other/num_home_img_11.png');background-size: contain;">
          <img class="item_img" :src='v.icon' />
          <div class="item_info">
            <v-card-title class="justify-center reset-title">{{ v.title }}</v-card-title>
            <v-card-text class="reset-text">{{ v.text }}</v-card-text>
          </div>
        </div>
      </div>
    </v-card>
    <v-card class="warps warp5" flat color="#f4f8fe">
      <div class="public-title">
        <p>PARTMER</p>
        <div>
          <span>合作伙伴</span>
        </div>
      </div>
      <div class="model_list5">
        <v-img
          src="https://h5.ophyer.cn/official_website/other/home-hzhb3.png"
        />
      </div>
    </v-card>
  </v-app>
</template>

<script>
import textData from '@/static/textData.js'
import qs from 'qs'
// import { throttle } from '@/utils/utils'
import { browserVersion } from "@/utils/utils"

export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      data: textData.Home,
      bannerList: [
        {
          bannerImg: 'https://h5.ophyer.cn/official_website/banner/num-banner-h-1.png',
          drawIng: 'https://h5.ophyer.cn/official_website/banner/num-banner-img-1.png',
          title: ["让人类进入元宇宙时代"],
          titleColor:"#FFFFFF",
          tip: ["SaaS产品矩阵，3D引擎，AI技术以及强大的XR硬件体系为企业","赋能，让我们携手共进，一起迎接元宇宙"],
        },
        {
          bannerImg: 'https://h5.ophyer.cn/official_website/banner/num-banner-h-2.png',
          drawIng: 'https://h5.ophyer.cn/official_website/banner/num-banner-img-2.png',
          title: ["为企业打造专属","元宇宙空间"],
          titleColor:"#FFFFFF",
          tip: ["让每一个企业都拥有属于自己的元宇宙空间，支持在任意元宇宙","世界中投放"],
        },
        {
          bannerImg: 'https://h5.ophyer.cn/official_website/banner/num-banner-h-3.png',
          drawIng: 'https://h5.ophyer.cn/official_website/banner/num-banner-img-3.png',
          title: ["飞天元宇宙"],
          titleColor:"#FFFFFF",
          tip: ["开放沉浸的3D虚拟世界，720度内部VR全景运用你的想象","力，让虚拟与现实完美融合"],
        },
        {
          bannerImg: 'https://h5.ophyer.cn/official_website/banner/num-banner-h-4.png',
          drawIng: 'https://h5.ophyer.cn/official_website/banner/num-banner-img-4.png',
          title: ["万目云 · SaaS平台"],
          titleColor:"#FFFFFF",
          tip: ["专属科技，赋能商业，让生产更效率，让运","营更智慧"],
        },
      ],
      modelList1: [
        {
          icon: "https://h5.ophyer.cn/official_website/other/num_home_img_01.png",
          title: "随心创作",
          text: "3D引擎，aPaaS引擎支撑，丰富的资源内容加持，让元宇宙内容创作更随心"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/other/num_home_img_2.png",
          title: "逼真的展示效果",
          text: "强大的渲染能力，让材质贴图，光效等画面细节都逼真无比"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/other/num_home_img_3.png",
          title: "沉浸式交互",
          text: "手势，面部表情，物理按键，全息操作面板，让交互更沉浸"
        }
      ],
      modelList2: [
        {
          img: "https://h5.ophyer.cn/official_website/other/num_home_img_4.png",
          text: "强大的功能配合丰富的资源<br/>让内容创造更高效"
        },
        {
          img: "https://h5.ophyer.cn/official_website/other/num_home_img_5.png",
          text: "引擎有着出色的渲染能力全局光照、光线追踪、混合渲染都不在话下"
        },
      ],
      modelList3: [
        {
          icon: "https://h5.ophyer.cn/official_website/icon/num-icon-apaas1.png",
          title: "元宇宙内容生产",
          text: "丰富的模板资源，简单易操作的界面让内容生产更简单"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/icon/num-icon-apaas2.png",
          title: "大数据",
          text: "SaaS独有的数据分析抓取能力为广告投放提供了有力的支撑，支持千人千策智能归因有效帮助客户拓展潜客"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/icon/num-icon-apaas3.png",
          title: "AI",
          text: "AI技术赋能，助力广告投放决策让广告投放更智能更精准"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/icon/num-icon-apaas4.png",
          title: "场景",
          text: "电商，3D展馆，智慧运营，汽车在线观看工业，教育，平台不断挖掘更多场景，为更多行业赋能"
        }
      ],
      modelList4: [
        {
          className: "surround_1",
          icon: "https://h5.ophyer.cn/official_website/icon/num-icon-surround1.png",
          title: "三维引擎",
          text: "FT引擎及Web3D引擎让产业数字化升级有坚实的基础"
        },
        {
          className: "surround_2",
          icon: "https://h5.ophyer.cn/official_website/icon/num-icon-surround2.png",
          title: "数字资产",
          text: "作为一种新的管理工具，通过其标准化的方法使企业能够更高效的评估企业数字资产"
        },
        {
          className: "surround_3",
          icon: "https://h5.ophyer.cn/official_website/icon/num-icon-surround3.png",
          title: "行业应用",
          text: "媒体运营，电商，企业展示，文旅，房地产，职业培训，工厂"
        },
        {
          className: "surround_4",
          icon: "https://h5.ophyer.cn/official_website/icon/num-icon-surround5.png",
          title: "内容推广",
          text: "自媒体，微信生态，各大地图及搜索引擎，均已支持"
        },
        {
          className: "surround_5",
          icon: "https://h5.ophyer.cn/official_website/icon/num-icon-surround4.png",
          title: "视觉与交互算法",
          text: "面部识别，手势识别，场景定位，物品识别，边缘识别，虚拟现实融合"
        }
      ],
      tab: null,
      vm: this,
      // screenY: 0,
      // windowWidth: document.documentElement.clientWidth
      isIE: browserVersion()
    }
  },
  watch: {
    // screenY: {
    //   handler(val, olVal) {
    //     console.log(this.windowWidth)
    //     if(olVal > val) {
    //       console.log(val/olVal)
    //       console.log('right')
    //       this.$refs.bannerimg[0].$el.childNodes[1].style.transform = 'scale(1) translate('+val/olVal*10+'px, 0px) rotate(0deg)';
    //     } else {
    //       console.log(val/olVal)
    //       console.log('left')
    //       this.$refs.bannerimg[0].$el.childNodes[1].style.transform = 'scale(1) translate('+-val/olVal*10+'px, 0px) rotate(0deg)';
    //     }
    //   },
    //   deep: true,
    //   immediate: true
    // }
  },
  created() {
  },
  methods: {
    goCaseDetail(key) {
      let query = {
        source: 'home',
        key: key,
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    },
    handleBannerBtn(info) {
      if(info.router){
        window.open(window.location.protocol + "//" + window.location.host + info.router);
      }else if(info.link){
        window.open(info.link);
      }else {
        this.showDialog = true;
      }
    },
    goLink() {
      let domains = process.env.VUE_APP_NODE_ENV === "production" ? process.env.VUE_APP_PRODUCTION_DOMAIN : process.env.VUE_APP_TEST_DOMAIN;
      domains = domains.split(",");
      window.open(domains[1]);
    }
    // move: throttle(() => {
    // }, 500, 100),
  }
}
</script>

<style lang="scss" scoped>

.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  .banner-box {
    margin-top: -100px;
    .banner-text {
      p:nth-child(1) {
        font-size: 82px;
        //font-family: SourceHanSansCN-Regular;
        font-weight: 400;
        color: #fff;
        line-height: 88px;
      }
      p:nth-child(2) {
        margin-top: 25px;
        font-size: 24px;
        font-weight: 100 !important;
        color: #fff;
      }
      .banner-btn {
        margin-top: 90px;
        width: 200px;
        height: 60px;
        background: #0568FD;
        border-radius: 35px;
        font-size: 20px !important;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  .metaTitle{
    .font-size-banner-title{
      font-size: 80px;
      font-weight: bold;
      color: #FFFFFF !important;
      mask-image:-webkit-gradient(linear, 0 0, 0 bottom, from(#FFFFFF), to(rgba(0, 0, 255, 0.5)));
    }
    .font-size-banner-tip{
      font-size: 28px;
      font-weight: 300;
      color: #FFFFFF !important;
      line-height: 36px;
      text-shadow: 0 6px 10px #000000;
      mask-image:-webkit-gradient(linear, 0 0, 0 bottom, from(#FFFFFF), to(rgba(0, 0, 255, 0.5)));
    }
  }
  @media (min-width: 960px) {
    .container {
      max-width: 1200px !important;
    }
  }
  
}

.warps {
  margin: 0 auto;
  text-align: center;
}

.warp1 {
  width: 100%;
  height: 420px;
  .model_list1 {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    .model_item{
      width: 300px;
      height: 210px;
      background: #FFFFFF;
      border-radius: 16px;
      padding: 15px 12px;
      .item_img{
        width: 100px;
        height: 100px;
        object-fit: contain;
      }
      .item_info{
        margin-top: 10px;
      }
    }
  }
}

.warp2 {
  width: 100%;
  height: 535px;
  .model_list2{
    width: 1200px;
    margin: 22px auto 0 auto;
    position: relative;
    .model_item{
      padding: 15px 20px;
      .item_img{
        width: 100%;
        //height: 100%;
        object-fit: contain;
      }
      .item_info{
        margin-top: 16px;
        padding: 0 16px;
        font-size: 18px;
        color: #333333;
        line-height: 20px;
      }
    }
  }
}

.warp3 {
  width: 100%;
  height: 460px;
  .model_list3 {
    width: 1200px;
    margin: 38px auto 0 auto;
    position: relative;
    .model_item{
      width: 260px;
      height: 220px;
      background: #FFFFFF;
      border-radius: 16px;
      padding: 10px 14px;
      .item_img{
        width: 100px;
        height: 100px;
        object-fit: contain;
      }
      .item_info{
        margin-top: 10px;
      }
    }
  }
}

.warp4 {
  width: 100%;
  height: 850px;
  .model_list4{
    width: 1200px;
    margin: 35px auto 0 auto;
    position: relative;
    .center_item{
      width: 342px;
      height: 342px;
      //background: #D8D8D8;
      border-radius: 130px;
      position: absolute;
      top: 50%;
      left: calc(50% - 171px);
      display: flex;
      align-items: center;
      justify-content: center;
      .item_img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .item_info{
        width: 126px;
        position: absolute;
        top: calc(50% + 10px);
        left: calc(50% - 64px);
        font-size: 30px;
        color: #FFFFFF;
        line-height: 1.5;
      }
    }
    .model_item{
      width: 260px;
      height: 240px;
      background: #FBFBFB;
      border-radius: 16px;
      padding: 10px 14px;
      .item_img{
        width: 100px;
        height: 100px;
        object-fit: contain;
      }
      .item_info{
        margin-top: 10px;
      }
    }
    .surround_1{
      position: absolute;
      top: 0;
      left: 0;
    }
    .surround_2{
      position: absolute;
      top: 260px;
      left: 100px;
    }
    .surround_3{
      position: absolute;
      top: 360px;
      left: calc(50% - 130px);
    }
    .surround_4{
      position: absolute;
      top: 260px;
      left: 780px;
    }
    .surround_5{
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.warp5 {
  width: 100%;
  height: 430px;
  padding-bottom: 50px;
  .public-title{
    height: 60px;
  }
  .model_list5{
    width: 1200px;
    margin: auto;
    position: relative;
  }
}
</style>
